<template>
<div class="container">
  <b-breadcrumb>
    <b-breadcrumb-item to="/scripts">Scripts</b-breadcrumb-item>
    <b-breadcrumb-item :to="'/scripts/'+script.script_id">{{script.name}}</b-breadcrumb-item>
    <b-breadcrumb-item v-if="$route.params.script_run_id" href="#">Run id {{$route.params.script_run_id}}</b-breadcrumb-item>
  </b-breadcrumb>
  <b-card :title="('Last runs of script &quot;'+script.name+'&quot;')" class="col-12" v-if="last_runs && !$route.params.script_run_id">
       <b-table hover :items="last_runs" tbody-tr-class="rowClass" :fields="fields">
         <template v-slot:cell(icons)="data">
           <router-link :to="'/scripts/'+script.script_id+'/'+data.item.script_run_id">
             <v-icon name="list" />
          </router-link>
         </template>
       </b-table>
       <span v-if="last_runs.length==0">No runs of script {{script_id}} registered</span>
  </b-card>
  <router-view :script="script" v-if="$route.params.script_run_id"></router-view>
</div>
</template>

<script>
export default {
  props: ["script_id"],
  data(){return{
    last_runs: null,
    timer: null,
    fields: [
      {label:'Run Id',key:'script_run_id'},
      {label:'Run Time',key:'run_time'},
      {label:'Status',key:'status'},
      {label:' ',key:'icons'}
    ],
    script: {}
  }},
  methods: {
    load_last_runs(){
      this.backend.get("/scripts/runs/"+this.script_id).then(resp => {
        if (resp.data) {this.last_runs = resp.data;}
        });    
    },
    load_data(){
      this.backend.get("/scripts/"+this.script_id).then(resp => 
      {
        if (resp.data) 
          {
            this.script = resp.data;
            this.timer = setInterval(this.load_last_runs,20000)
            this.load_last_runs()  
          }
      });
    }
  },
  mounted(){
    this.load_data()
  },
  beforeDestroy(){
    clearInterval(this.timer)
  }
}
</script>